<template>
    <div>
        <div v-if="imageUrl" class="d-flex justify-center ma-4 image-container">
            <v-img contain width="350" height="250" :src="imageUrl"></v-img>
            <v-btn class="remove-media" icon depressed @click="removeImage()">
                <v-icon color="error">
                    fa-solid fa-x
                </v-icon>
            </v-btn>
        </div>
        <v-file-input
            v-else
            v-model="imageUrl"
            counter
            outlined
            color="primary"
            :placeholder="$t(`builder.components.fileInput`)"
            class="file-input input-remove-spacing"
            :accept="acceptedFileTYpes"
            :show-size="1000"
            height="120"
            required
            :error-messages="imageErrors"
            @input="
                $v.imageUrl.$touch();
                inputMedia();
            "
            @change="
                $v.imageUrl.$touch();
                inputMedia();
            "
        >
            <template v-slot:selection="{ index, text }">
                <v-chip color="primary" dark label small>
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { blobToBase64 } from "@/helpers/generalHelpers.js";
export default {
    name: "StepImage",

    validations: {
        imageUrl: {
            required,
        },
    },

    props: {
        steps: {
            required: false,
        },
    },

    data() {
        return {
            acceptedFileTYpes: "image/*",
            imageUrl: null,
        };
    },

    computed: {
        imageErrors() {
            const errors = [];
            if (!this.$v.imageUrl.$dirty) return errors;
            !this.$v.imageUrl.required &&
                errors.push(this.$i18n.t(`messages.error.required`));
            return errors;
        },
    },

    methods: {
        inputMedia() {
            let media = this.imageUrl;
            blobToBase64(media).then((resault) => {
                this.imageUrl = resault;
            });
            this.$emit("update:step-image", media);
            this.$emit("update:in-valid", this.$v.$invalid);
        },

        removeImage() {
            this.imageUrl = null;
            this.$emit("update:step-image", null);
        },
    },

    created() {
        if (this.steps?.image_url) {
            this.imageUrl = this.steps.image_url;
            this.$emit("update:step-image", this.imageUrl);
        }
    },
};
</script>
